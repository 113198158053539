//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    selUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headerCellStyle: {
        textAlign: "center",
        fontSize: "18",
        background: "#f8f8f8",
        color: "#4a4a4a",
      },
    };
  },
  methods: {
    cellClick(row) {
      console.log(row, this.selUser);
      this.$emit("handleKeyWord", row, this.selUser);
    },
  },
};
